* {
  font-family: Arial, Helvetica, sans-serif;
}

._page_outer {
  display: flex;
}

._page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

a {
  color: #000;
  text-decoration: none;
}

._page_ticket_list_screen .count {
  font-size: 23px;
  font-weight: bold;
}

._page_ticket_list_screen .list {
  display: flex;
  flex-direction: column;
}

._page_ticket_list_screen .list .item {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  border-bottom: 1px solid #00000020;
}

._page_ticket_list_screen .list .item .details {
  display: flex;
  justify-content: space-between;
}

._page_ticket_list_screen .list .item .actions {
  margin-top: 10px;
}

._page_ticket_screen .title {
  font-size: 20px;
  font-weight: bold;
}

._page_ticket_screen .list {
  display: flex;
  flex-direction: column;
  height: 312px;
  overflow: auto;
}

._page_ticket_screen .list .item {
  background: rgb(233, 233, 233);
  margin-bottom: 12px;
  padding: 12px 20px;
  border-radius: 12px;
  color: #000000;
  max-width: 312px;
  display: flex;
  flex-direction: column;
  margin-right: inherit;
  border-bottom-left-radius: 0px;
  font-size: 15px;
}

._page_ticket_screen .list .sent {
  background: rgb(0, 106, 255);
  color: #ffffff;
  margin-right: inherit;
  margin-left: auto;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 0px;
}

._page_ticket_screen .list .item:last-child {
  margin-bottom: 0px;
}

._page_ticket_screen .list .item .text {
  margin-bottom: 12px;
}

._page_ticket_screen .list .item .created_date {
  opacity: 0.7;
}

._page_ticket_screen textarea {
  height: 128px;
}

._page_ticket_screen .actions {
  display: flex;
  align-items: center;
}

._button {
  display: flex;
  background: #000000;
  color: #ffffff;
  padding: 12px 32px;
  border-radius: 100px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: fit-content;
  user-select: none;
  transition: 0.1s ease-in;
}

._button:hover {
  opacity: 0.8;
}

._button:active {
  opacity: 0.7;
}

._menu {
  display: flex;
  flex-direction: column;
  width: 192px;
  padding: 20px;
  background: #ffffff;
}

._menu .list {
  display: flex;
  flex-direction: column;
}

._menu .list .item {
  display: flex;
}

._translate .translated_text {
  font-style: italic;
}